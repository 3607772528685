import { set } from '@/utils/store.helper';
import moment from 'moment';

export default ({
  state: {
    dataProcessList: [],
    dataSourceList: [
    ], // 数据源列表
    curDataProcess: {}, // 当前数据源处理
    dataParamList: [], // 数据源参数列表
    parameterList: [], // 通讯参数列表
    dataSourceTreeList: [], // 数据源树形列表
    dataList: [], // 数据列表
    statusList: [
      {
        // text: '在线',
        value: '1',
        color: 'success',
      },
      {
        // text: '停止',
        value: '0',
        color: 'error',
      },
    ],
    processTypeList: [
      {
        text: 'dataTableClean',
        value: '1',
      },
      {
        text: 'dataTableMerge',
        value: '2',
      },
    ], // 处理类型列表
    cleanTypeList: [
      {
        text: 'duplicateDelete',
        value: '1',
      },
      {
        text: 'errorDelete',
        value: '2',
      },
    ], // 清洗类型列表
    typeList: [
      {
        text: 'between',
        value: '1',
      },
      {
        text: '≤',
        value: '2',
      },
      {
        text: '≥',
        value: '3',
      },
      {
        text: '>',
        value: '4',
      },
      {
        text: '<',
        value: '5',
      },
      {
        text: '=',
        value: '6',
      },
      {
        text: '≠',
        value: '7',
      },
    ], // 类型列表
    containList: [
      {
        text: '≥≥',
        value: '1',
      },
      {
        text: '≥>',
        value: '2',
      },
      {
        text: '>≥',
        value: '3',
      },
      {
        text: '>>',
        value: '4',
      },
    ], // 包含列表
    dataTypeList: [], // 数据类型列表
  },
  mutations: {
    setDataProcessList: set('dataProcessList'),
    setDataSourceList: set('dataSourceList'),
    setCurDataProcess: set('curDataProcess'),
    setDataParamList: set('dataParamList'),
    setDataList: set('dataList'),
    setParameterList: set('parameterList'),
    setDataSourceTreeList: set('dataSourceTreeList'),
    setDataTypeList: set('dataTypeList'),
  },
  actions: {
    async getDataProcessList({ commit }, needAlert) {
      const result = await this.$basicGet('dataSourceProcess/list');
      if (!result) {
        return;
      }
      if (result.rows) {
        const list = result.rows.map((item) => {
          const obj = {
            ...item,
          };
          return obj;
        });
        commit('setDataProcessList', list);
      }
      if (needAlert) {
        commit(
          'helper/setAlert',
          {
            show: true,
            type: 'success',
            message: 'successAction',
          },
          { root: true },
        );
      }
    },
    async getDataSourceList({ commit }, needAlert) {
      const result = await this.$basicGet('dataSourceTable/tableList');
      if (!result) {
        return;
      }
      if (result.rows) {
        const list = result.rows.map((item) => {
          const obj = {
            ...item,
            name: item.dataSourceName,
          };
          return obj;
        });
        commit('setDataSourceList', list);
        const treeList = [
          {
            dataSourceNumber: 'parent-1',
            name: 'dataSource',
            children: list.filter((item) => item.dataSourceType === '1'),
          },
          {
            dataSourceNumber: 'parent-2',
            name: 'dataProcess',
            children: list.filter((item) => item.dataSourceType === '2'),
          },
        ];
        commit('setDataSourceTreeList', treeList);
      }
      if (needAlert) {
        commit(
          'helper/setAlert',
          {
            show: true,
            type: 'success',
            message: 'successAction',
          },
          { root: true },
        );
      }
    },
    async updateProcess({ commit }, payload) {
      const result = await this.$basicPut('dataSourceProcess', payload);
      if (!result) {
        return false;
      }
      commit(
        'helper/setAlert',
        {
          show: true,
          type: 'success',
          message: 'successAction',
        },
        { root: true },
      );
      return true;
    },
    async activeProcess({ commit }, payload) {
      const result = await this.$basicPut('dataSourceProcess/activeDataSourceProcess', payload);
      if (!result) {
        return false;
      }
      commit(
        'helper/setAlert',
        {
          show: true,
          type: 'success',
          message: 'successAction',
        },
        { root: true },
      );
      return true;
    },
    async addProcess({ commit }, payload) {
      const result = await this.$basicPost('dataSourceProcess', payload);
      if (!result) {
        return false;
      }
      commit(
        'helper/setAlert',
        {
          show: true,
          type: 'success',
          message: 'successAction',
        },
        { root: true },
      );
      return true;
    },
    async getDataProcessById({ commit }, id) {
      const result = await this.$basicGet(`dataSourceProcess/${id}`);
      if (!result) {
        return false;
      }
      commit('setCurDataProcess', result.data || {});
      return result.data;
    },
    async getDataParametersById({ commit }, id) {
      const result = await this.$basicGet(`dataSourceProcessParam/list?dataSourceProcessId=${id}`);
      if (!result) {
        return [];
      }
      if (result.rows) {
        const list = result.rows.map((item) => {
          const obj = {
            ...item,
          };
          return obj;
        });
        commit('setDataParamList', list);
        return list;
      }
      return [];
    },
    async getDataParametersWithType(_, { id, dataSourceType }) {
      let result = null;
      if (dataSourceType === '1') {
        result = await this.$basicGet(`param/list?dataSourceId=${id}`);
      } else if (dataSourceType === '2') {
        result = await this.$basicGet(`dataSourceProcessParam/list?dataSourceProcessId=${id}`);
      }
      if (!result) {
        return [];
      }
      if (result.rows) {
        const list = result.rows.map((item) => {
          const obj = {
            ...item,
            id: item.paramId ? item.paramId : item.id,
          };
          return obj;
        });
        return list;
      }
      return [];
    },
    async updateParameter({ commit }, payload) {
      const result = await this.$basicPost('dataSourceProcessParam/updateColumnName', payload);
      if (!result) {
        return false;
      }
      commit(
        'helper/setAlert',
        {
          show: true,
          type: 'success',
          message: 'successAction',
        },
        { root: true },
      );
      return true;
    },
    async getDataTypeList({ commit }) {
      const result = await this.$systemGet('dict/data/list?dictType=sys_parameter_data');
      if (!result) {
        return false;
      }
      commit('setDataTypeList', result.rows.map((item) => {
        const obj = {
          ...item,
          id: item.dictCode,
        };
        return obj;
      }));
      return true;
    },
    // 导出表
    async eportSampleExcel(_, { payload, fileName }) {
      let { exportType } = payload;
      if (exportType === 'excel') {
        exportType = 'xlsx';
      }
      const filename = fileName || `数据_${moment().format('YYYY-MM-DD HH:mm:ss')}.${exportType}`;
      const result = await this.$basicExport('dataSourceProcessParam/exportBasicDataSourceParamList', filename, payload);
      if (!result) {
        return false;
      }
      return true;
    },
  },
});
